
enum OrderStatus {
    Requested = "requested",
    Approved = "approved",
    Received = "received",
    Ongoing = "ongoing",
    Finished = "finished",
    Paid = "paid",
    Taken = "taken",
    Closed = "closed",
    Cancelled = "cancelled",
}

export default OrderStatus;