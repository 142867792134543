import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import AlertContext from "../Contexts/AlertContext";
import { useNavigate } from "react-router-dom";
import { Login } from "../APIs/AuthAPI";
import AuthContext from "../Contexts/AuthContext";
import LockOpenIcon from '@mui/icons-material/LockOpen';

function LoginPage() {

    const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);
    const { setLoggedUser, setLoggedIn, setCookie } = useContext(AuthContext);

    const [fields, setFields] = useState<{ Phone: string, Password: string }>({
        Phone: "",
        Password: ""
    });

    const style = {
        mobile: {
            width: "100%",
            // height: "100%"
        },
        desktop: {
            width: "35%"
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const fieldSetter = (type: ("Phone" | "Password"), value: any) => {
        setFields({ ...fields, [type]: value });
    }

    const submitForm = async (event: any) => {
        event.preventDefault();

        setTimeout(() => { setWaiting(true) }, 1);
        try {
            let response = await Login(fields.Phone, fields.Password);
            setLoggedUser(response);
            setCookie("login_token", response.Token, { path: "/", secure: true, maxAge: 86400 });
            setLoggedIn(true);

            setWaiting(false);
            setAlert("working", "info");
            navigate("/");
        } catch (error: any) {
            setWaiting(false);
            setAlert(error.message, "error");
        }

    }

    return (
        <div className="w-100 h-100" style={{ overflowX: "hidden", overflowY: "auto", background: (isMobile ? "white" : "transparent") }}>
            <div className={isMobile ? "login-form-card" : "card login-form-card"} style={isMobile ? style.mobile : style.desktop}>
                <div className="card-body">
                    <div className="d-flex">
                        <img src="./images/main_logo.svg" alt="image" style={{ width: "100px", height: "auto" }} />
                        <div className="w-100">
                            <h3 className="card-title pt-2">Sign In</h3>
                            <span className="card-subtitle">Sign Into your account here!</span>
                        </div>
                    </div>

                    <form action="post" className="w-100 mt-5 ps-4 pe-4" onSubmit={submitForm}>
                        <div className="mb-3">
                            <label htmlFor="email_input" className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" required value={fields.Phone} onChange={(event: any) => { fieldSetter("Phone", event.target.value) }} id="email_input" placeholder="+251" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password_input" className="form-label" >Password</label>
                            <input type="password" className="form-control" id="password_input" placeholder="Enter Your Password" required value={fields.Password} onChange={(event: any) => { fieldSetter("Password", event.target.value) }} />
                        </div>

                        <button className="btn btn-link" onClick={() => {navigate("/reset")}}>Did you forget your password?</button> <br />

                        <button className={isMobile ? "btn btn-primary btn-lg mt-3 w-100" : "btn btn-primary mt-3"}>
                            <LockOpenIcon sx={{fontSize: 25, marginRight: "10px"}}/>
                            Sign In
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;